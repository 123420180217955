import React, {
  ReactNode,
  FunctionComponent,
  Fragment,
  useContext,
  createContext,
} from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { FieldRenderProps } from 'react-final-form';
import Downshift from 'downshift';
import { InputField, Card, Text, Label } from 'mdlkit';
import { useTheme } from '../hooks';

const StyledCard = styled(Card)`
  position: absolute;
  width: 100%;
  top: 100%;
  background: ${themeGet('colors.white')};
  z-index: 1;
`;

export const StyledTitle = styled(Text)`
  font-family: ${({ theme }) => theme.headingFont};
  padding: 6px 12px;
`;

const StyledItem = styled(Text)`
  font-family: ${({ theme }) => theme.headingFont};
  padding: 6px 12px;

  u {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.tertiary};
  }

  &[aria-selected='true'] {
    u {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export interface Item {
  label: string;
  value: string;
}

interface AutocompleteContextType {
  getItemProps: any;
  highlightedIndex: number | null;
  inputValue: string | null;
  getMenuProps: any;
  isOpen: boolean;
}

export const AutocompleteContext = createContext<AutocompleteContextType>({
  getItemProps: () => {},
  highlightedIndex: null,
  inputValue: null,
  getMenuProps: null,
  isOpen: false,
});

interface AutocompleteMenuProps {
  children?: ReactNode;
}

export const AutocompleteMenu: FunctionComponent<AutocompleteMenuProps> = ({
  children,
}: AutocompleteMenuProps) => {
  const { getMenuProps, isOpen } = useContext(AutocompleteContext);

  return isOpen ? (
    <StyledCard borderRadius={0} {...getMenuProps({ isOpen })}>
      {children}
    </StyledCard>
  ) : null;
};

interface AutocompleteItemListProps {
  items: Item[];
}

export const AutocompleteItemList: FunctionComponent<AutocompleteItemListProps> = ({
  items,
}: AutocompleteItemListProps) => (
  <Fragment>
    {items.map(({ label }, index) => (
      <AutocompleteItem key={label} label={label} index={index} />
    ))}
  </Fragment>
);

interface AutocompleteItemProps {
  label: string;
  index: number;
}

export const AutocompleteItem: FunctionComponent<AutocompleteItemProps> = ({
  label,
  index,
}: AutocompleteItemProps) => {
  const theme = useTheme();
  const { inputValue, getItemProps, highlightedIndex } = useContext(
    AutocompleteContext
  );

  const getLabelWithHighlight = () => {
    const inputValueRegExp = new RegExp(
      `(${(inputValue || '').replace(new RegExp('[^a-z0-9 .,]+', 'ig'), '')})`,
      'ig'
    );
    return label.replace(inputValueRegExp, '<u>$1</u>');
  };

  return (
    <StyledItem
      as="div"
      {...getItemProps({
        index,
        item: label,
        style: {
          color:
            highlightedIndex === index ? theme.colors.white : theme.colors.text,
          backgroundColor:
            highlightedIndex === index
              ? theme.colors.primary
              : theme.colors.white,
          fontWeight:
            highlightedIndex === index
              ? theme.fontWeights.bold
              : theme.fontWeights.normal,
        },
      })}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: getLabelWithHighlight(),
        }}
      />
    </StyledItem>
  );
};

type AutocompleteInputProps = {
  id?: string;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  items?: Item[];
  onInputValueChange?(value: string): void;
  onSelect?(value: string): void;
  icon?: ReactNode;
  openOnFocus?: boolean;
  menu?: ReactNode;
} & FieldRenderProps<any>;

const AutocompleteInput: FunctionComponent<AutocompleteInputProps> = ({
  id,
  input,
  meta,
  icon,
  label: formLabel,
  placeholder,
  items,
  onInputValueChange,
  onSelect,
  openOnFocus,
  menu,
  ...rest
}: AutocompleteInputProps) => {
  const itemToString = (item) => item || '';

  return (
    <Downshift
      id={id}
      {...input}
      onInputValueChange={(inputValue) => {
        input.onChange(inputValue);
        return typeof onInputValueChange !== 'undefined'
          ? onInputValueChange(inputValue)
          : null;
      }}
      onSelect={(selectedItem) => {
        return typeof onSelect !== 'undefined' ? onSelect(selectedItem) : null;
      }}
      itemToString={itemToString}
      selectedItem={input.value}
    >
      {({
        getInputProps,
        getLabelProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        openMenu,
      }) => {
        return (
          <div style={{ position: 'relative', width: '100%' }}>
            <AutocompleteContext.Provider
              value={{
                getItemProps,
                highlightedIndex,
                inputValue,
                getMenuProps,
                isOpen,
              }}
            >
              {/*
                // @ts-ignore */}
              <InputField
                label={
                  formLabel ? (
                    <Label {...getLabelProps()}>{formLabel}</Label>
                  ) : null
                }
                icon={icon || null}
                meta={meta}
                input={input}
                {...getInputProps({
                  onFocus: openOnFocus ? openMenu : null,
                  name: input.name,
                  placeholder,
                })}
                onBlur={(event) => input.onBlur(event)}
                {...rest}
              />

              {typeof menu === 'object' && items && !!items.length
                ? menu
                : null}
              {typeof menu === 'undefined' && items && !!items.length ? (
                <AutocompleteMenu>
                  <AutocompleteItemList items={items} />
                </AutocompleteMenu>
              ) : null}
            </AutocompleteContext.Provider>
          </div>
        );
      }}
    </Downshift>
  );
};

export default AutocompleteInput;
