import { normalizeNumbersOnly } from '../shared/normalize';

export const normalizeZipCode = (value: string): string => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  return `${onlyNums.slice(0, 5)}`;
};

export const normalizeNumberOnlyWithLength = (
  value: string,
  length: number
): string => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  return `${onlyNums.slice(0, length)}`;
};

export { normalizeNumbersOnly };
