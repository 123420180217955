import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { Dialog } from '@reach/dialog';
import { CloseButton, Heading, HeadingProps, Box } from 'mdlkit';

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: ${({ theme }) => theme.space.xs}px;
  right: ${({ theme }) => theme.space.xs}px;
`;

interface ModalProps {
  ariaLabel: string;
  isOpen: boolean;
  onDismiss(): void;
  children: ReactNode;
}

type HeaderWithoutAs = Omit<HeadingProps, 'as'>;

interface ModalHeaderProps extends HeaderWithoutAs {
  children: ReactNode;
  color?: string;
}

interface ModalContentProps {
  children: ReactNode;
}

const ModalComponent: FunctionComponent<ModalProps> = ({
  ariaLabel,
  isOpen,
  onDismiss,
  children,
}: ModalProps) => (
  <Dialog
    style={{ position: 'relative' }}
    isOpen={isOpen}
    onDismiss={onDismiss}
    aria-label={ariaLabel}
  >
    <StyledCloseButton onClick={onDismiss} />

    {children}
  </Dialog>
);

const Header: FunctionComponent<ModalHeaderProps> = ({
  children,
  color,
  ...rest
}: ModalHeaderProps) => (
  <Heading as="h2" pb="sm" color={color as string} {...rest}>
    {children}
  </Heading>
);
const Content: FunctionComponent<ModalContentProps> = ({
  children,
}: ModalContentProps) => <Box>{children}</Box>;

export const Modal = Object.assign(ModalComponent, { Header, Content });

Modal.displayName = 'Modal';

export default Modal;
