export const INVALID_EMAIL = 'INVALID_EMAIL';
export const INVALID_PHONE = 'INVALID_PHONE';
export const EMAIL_REQUIRED = 'EMAIL_REQUIRED';
export const INCORRECT_USER_OR_PASSWORD = 'INCORRECT_USER_OR_PASSWORD';
export const LOCKED_USER = 'LOCKED_USER';
export const ERROR_403_BLOCKED = 'ERROR_403_BLOCKED';
export const MISSING_PROFILE = 'MISSING_PROFILE';
export const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';
export const AUTH_CODE_ERROR = 'AUTH_CODE_ERROR';
export const AUTH_CODE_SESSION_ERROR = 'AUTH_CODE_SESSION_ERROR';
export const USERNAME_OR_EMAIL_REQUIRED = 'USERNAME_OR_EMAIL_REQUIRED';
export const INCORRECT_USERNAME_OR_EMAIL = 'INCORRECT_USERNAME_OR_EMAIL';
export const DATA_REQUIRED = 'DATA_REQUIRED';
export const INCORRECT_DATA = 'INCORRECT_DATA';
export const UNABLE_TO_UPDATE_PASSWORD = 'UNABLE_TO_UPDATE_PASSWORD';
export const MULTIPLE_PHONE_NUMBERS = 'MULTIPLE_PHONE_NUMBERS';
export const TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS';
export const INVALID_PASSWORD = 'Invalid password. Please try again.';
