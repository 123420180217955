import styled from 'styled-components';
import { Flex, Box, Button, LoadingButton } from 'mdlkit';
import { space, SpaceProps } from 'styled-system';

export const StyledForm = styled.form<SpaceProps>`
  padding-top: ${({ theme }) => theme.space.xs + theme.space.sm}px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > ${Flex} {
    width: 100%;
  }

  & > ${Box}:not(:first-child) {
    margin-top: ${({ theme }) => theme.space.xs + theme.space.sm}px;
  }

  & > ${Flex}:not(:first-child) {
    margin-top: ${({ theme }) => theme.space.xs + theme.space.sm}px;
  }
  ${space}
`;

export const StyledFlex = styled(Flex)<{ mt?: number | string }>`
  flex-direction: column;
  width: 100%;

  ${({ mt }) =>
    typeof mt !== 'undefined' ? `margin-top: ${mt} !important;` : ``}

  & > ${Box} {
    width: 100%;

    & + ${Box} {
      margin-top: ${({ theme }) => theme.space.xs + theme.space.sm}px;
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;

    & > ${Box} {
      width: 50%;

      &:first-child {
        padding-right: ${({ theme }) => theme.space.xs}px;
      }

      & + ${Box} {
        padding-left: ${({ theme }) => theme.space.xs}px;
        margin-top: ${({ theme }) => theme.space.none}px;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  margin: ${({ theme }) => theme.space.md}px auto 0 auto;
`;

export const FormSubmitButton = styled(Button)`
  margin-top: ${({ theme }) => theme.space.md}px;
`;

export const FormSubmitLoadingButton = styled(LoadingButton)`
  margin: ${({ theme }) => theme.space.md}px auto 0 auto;
`;

export const ShowInMobile = styled(Box)`
  display: block;
  ${({ theme }) => theme.mediaQueries.lg} {
    display: none;
  }
`;

export const ShowInDesktop = styled(Box)`
  display: none;
  ${({ theme }) => theme.mediaQueries.lg} {
    display: block;
  }
`;

export const CustomSignInButton = styled(Button)`
  margin: 15px auto 0 auto;
  padding: 12px 22px;
  background: none;
  border: solid thin ${({ theme }) => theme.colors.primary};
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    border: ${({ theme }) => theme.colors.secondary};
    color: #fff;
  }
`;

export const CustomFormSubmitButton = styled(Button)`
  margin-top: ${({ theme }) => theme.space.md}px;
  width: 100%;
`;

export const GrayLinesSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const GrayLine = styled.hr`
  background: #d5dfe6;
  height: 1px;
  width: 100%;
  margin: 20px 0;
  border: none;
`;

export const OrText = styled.div`
  margin: 0 20px;
`;
